class Contact {
    constructor() {

        this.init();
        this.add_cf7_class();

    }

    init(){
        var fullPath = document.getElementById('bijlage');
        var uploadMsg = document.getElementById("uploadMsg");

        fullPath.onchange = function() {
            let input = this.files[0];
            if (input) {
              //process input
              uploadMsg.dataset.file = fullPath.value.replace("C:\\fakepath\\", "");
            } else {
              uploadMsg.dataset.file = "Geen bestand gekozen";
            }
          };
    }
    add_cf7_class(){

        var errorElement = document.querySelectorAll("div.field-message");

        document.querySelector("input.wpcf7-submit").addEventListener("click", function(){

            var errorElement = document.querySelectorAll("div.field-message");
            errorElement[0].classList.remove("shown");
        });

        
        document.addEventListener( 'wpcf7invalid', function( event ) {
            errorElement[0].classList.add("error");
            errorElement[0].classList.add("shown");
        }, false );
        document.addEventListener( 'wpcf7mailsent', function( event ) {
            errorElement[0].classList.add("shown");
            errorElement[0].classList.remove("error");
            errorElement[0].classList.add("complete");
        }, false );
    }
}