class Filter {
    constructor() {

        this.changeInputValues();

        const form = document.getElementById('filter');
        const textfield = document.getElementById('nameActie');
        const selectCampaigns = document.getElementById('campaigns');
        const selectOrder = document.getElementById('sortby');
        const selectMeta_project_1 = document.getElementById('meta_project_1');
        const status = document.getElementById('project_state');

        form.addEventListener('submit', e => {
          e.preventDefault()
          e.stopPropagation()

          this.init()
        });

        textfield.addEventListener("keyup", event => {
            this.init();
        });

        selectCampaigns.addEventListener("change", event => {
            this.init();
        });

        selectOrder.addEventListener("change", event => {
            this.init();
        });

        status.addEventListener("change", event => {
            this.init();
        });

        selectMeta_project_1.addEventListener("change", event => {
            this.init();
        });

        this.init()
    }
    init(){
        jQuery(function($){

            var filter = $('#filter');

            $.ajax({

                url:filter.attr('action'),
                data:filter.serialize(), // form data
                type:filter.attr('method'), // POST

                beforeSend:function(xhr){

                    document.getElementById("ajaxspinner").style.display = "flex";

                    var filtre = [$("#nameActie").val(), $("#campaigns").val(),$("#sortby").val(),$("#meta_project_1").val(),$("#project_state").val()]
					var filtre = JSON.stringify(filtre);
					document.cookie = "filters="+filtre+"; expires=; domain=; path=/";

                },
                success:function(data){

                    document.getElementById("ajaxspinner").style.display = "none";

                    $('#ajaxresponse').html(data); // insert data

                    $(".dynamicBar").each(function(){

                        $(this).find(".dynamicBar-inner").animate({
                            width: $(this).attr("data-width")
                        },2000)

                    });

                }
            });

            return false;

        });
    }

    changeInputValues(){

        function getCookie(cname) {

            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');

            for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
            }
            return "";
        }

        var filters = getCookie("filters");

        if(filters){

            filters = JSON.parse(filters);

            var a = document.getElementById('nameActie');
            var b = document.getElementById('campaigns');
            var c = document.getElementById('sortby');
            var d = document.getElementById('meta_project_1');
            var e = document.getElementById('project_state');

            if(filters[0]!=""){
                a.value = filters[0];
            }
            b.value = filters[1];
            c.value = filters[2];
            d.value = filters[3];
            e.value = filters[4];
        }
    }
    logKey() {
    }
}