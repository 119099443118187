let Browser = (function($) {
    function Browser() {}
  
    const browser = bowser;
  
    Browser.NAME = bowser.name;
    Browser.VERSION = bowser.version;
    Browser.OS = bowser.osname;
    Browser.OSVERSION = bowser.osversion;
  
    function toSlug(str, separator) {
      str = str.trim();
      str = str.toLowerCase();
  
      // remove accents, swap ñ for n, etc
      const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      const to = "aaaaaaeeeeiiiioooouuuunc------";
  
      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }
  
      return str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-") // collapse dashes
        .replace(/^-+/, "") // trim - from start of text
        .replace(/-+$/, "") // trim - from end of text
        .replace(/-/g, separator);
    }
  
    $("html").addClass(
      toSlug(Browser.NAME.toLowerCase(), '-') + "-" + parseInt(Browser.VERSION)
    );
  
    return Browser;
  })(jQuery);
  