class Header {
    constructor() {

        this.addActiveClass();
        this.openMenuSmarphone();

    }
    addActiveClass(){

        var subclassitem = document.querySelectorAll("li.current-menu-item");
        var subclassitem2 = document.querySelectorAll("#seperate_hmenu_translation_auth_search-menu ul li.current-menu-item");

        if(subclassitem['length']>0){
            subclassitem[0].lastChild.classList.add("active")
        }
        if(subclassitem2['length']>0){
            subclassitem2[0].lastChild.classList.add("active")
        }
    }
    openMenuSmarphone(){

        var modal = document.getElementById("seperate_hmenu_translation_auth_search-menu");

        if(!modal) return

        var modalCloseButton = modal.querySelector("button.close");

        document.getElementById("menuModalTriger").addEventListener("click", function() {
            modal.classList.toggle("modal--open");
        });

        modalCloseButton.addEventListener("click", function() {
            modal.classList.toggle("modal--open");
        });



    }
}

