class CookiesBar {
    constructor(options) {
  
      this.container = jQuery(".js-cookie_popup");
  
      this.btn = this.container.find(".js-cta_cookie");
      
      this.btn_secondary = jQuery(".js-cookie_popup__icon"); // Button that displays the pop-up.
  
  
      /* #Definition of variables in order to give them an attribute after */
  
        this.analytical_btn = this.container.find("#cookie_analytical")
  
        this.marketing_btn = this.container.find("#cookie_marketing")
  
      /* /Definition of variables in order to give them an attribute after */
  
      /* #Checking if the cookie is setup. If so, I check the checkboxes according to the values ​​of the cookie  */
  
        if(Cookies.get("accept-cookies")){
  
          var cookie = Cookies.get("accept-cookies").substring(1, Cookies.get("accept-cookies").length-1).replace(/"/g,"").split(","); // Transforms string of cookie "[true,true,true] in array"
  
          // cookie[0] --> always true. Cookie required.
          // cookie[1] --> true or false. Analytics Cookie.
          // cookie[2] --> true or false. Marketing Cookie.
  
          if(cookie[0]=="analytics"){
  
            this.analytical_btn[0].setAttribute("checked", "checked")
  
          }
          if(cookie[1]=="marketing"){
  
            this.marketing_btn[0].setAttribute("checked", "checked")
            
          }   
        }
  
      /* /Checking if the cookie is setup. If so, I check the checkboxes according to the values ​​of the cookie  */  
  
      this.events();
  
      this.show();
  
    }
  
    events() {
  
      // Hide the pop up if click on this.btn
  
      this.btn.on(Event.CLICK, (e) => {
  
        e.preventDefault();
  
        e.stopPropagation();
  
        this.hide();
  
      });
  
      // Show the pop up if click on this.btn_secondary
  
      this.btn_secondary.on(Event.CLICK, (e) => {
  
        e.preventDefault();
  
        e.stopPropagation();
  
        var tl = new TimelineMax();
  
        tl.set(this.container, {
          y: "100%",
          display: "flex"
        }).to(
          this.container,
          0.3,
          {
            y: "0%"
          }
        );
  
      });
    }
  
    // show cookie pop up
  
    show() {
      if (Cookies.get("accept-cookies")) return false;
  
      var tl = new TimelineMax();
  
      tl.set(this.container, {
        y: "100%",
        display: "flex"
      }).to(
        this.container,
        0.3,
        {
          y: "0%"
        },
        "+=1"
      );
  
      return true;
    }
  
    // Hide cookie pop up
  
    hide() {
      var tl = new TimelineMax({
        onComplete: function() {
  
          // Set up the cookie with 3 value corresponding with the 3 types of cookie : required Cookie, Analytics Cookie and Marketing Cookie.
  
          var analytical_cookies = document.getElementById("cookie_analytical").checked ? "analytics" : "" ;
          var marketing_cookies = document.getElementById("cookie_marketing").checked ? "marketing" : "" ;
  
          Cookies.set("accept-cookies", [analytical_cookies,marketing_cookies], { expires: 365 });
        }
      });
  
      tl.to(this.container, 0.3, {
        y: "100%"
      });
      return false;
    }
  }
  