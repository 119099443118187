let Device = (function($) {

    function Device() {}

    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let md = new MobileDetect(userAgent);

    Device.TYPE = md.phone()
        ? 'mobile'
        : (md.tablet()
            ? 'tablet'
            : 'desktop')

    Device.DESKTOP = !md.mobile()
        ? true
        : false
    Device.TABLET = md.tablet()
        ? true
        : false
    Device.MOBILE = md.phone()
        ? true
        : false

    $('html').addClass(Device.TYPE);

    return Device;

})(jQuery);
