class Home {
    constructor() {
    
        this.dynamiqueProjectBar();
        this.initMap();
    }

    dynamiqueProjectBar(){
             jQuery(".dynamicBar").each(function(){
             jQuery(this).find(".dynamicBar-inner").animate({
                 width: jQuery(this).attr("data-width")
             },2000)
         });
    }

    initMap() {
        // The location of Uluru

        var map = document.getElementById('gmap');

        var markersInfos =  map.dataset.marker;

        var locations = JSON.parse(markersInfos);

          var map = new google.maps.Map(document.getElementById('gmap'), {
            zoom: 14,
            center: new google.maps.LatLng(51.0533388, 3.7211744)
          });

          var infowindow = new google.maps.InfoWindow();
      
          var marker, i;

          var bounds = new google.maps.LatLngBounds();
      
          for (i = 0; i < locations.length; i++) {
            
            if(locations[i][1] && locations[i][2]){
              marker = new google.maps.Marker({
                position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                map: map,
                icon:'https://storage.googleapis.com/koalect/images/clients/stadgent/website/googlemapicon.svg'
              });
        
              google.maps.event.addListener(marker, 'click', (function(marker, i) {
                return function() {
                  infowindow.setContent(locations[i][0]);
                  infowindow.open(map, marker);
                }
              })(marker, i));
  
              bounds.extend(marker.position);
            }  
          }

          /*if(locations.length>1){

            map.fitBounds(bounds);

          }*/
      }
}