class Authentification {
  constructor() {
    this.profile = document.querySelector('.js-authentification-profile')
    this.login = document.querySelector('.js-authentification-login')

    this.profile2 = document.querySelector('.js-authentification-profile2')
    this.login2 = document.querySelector('.js-authentification-login2')

    this.token = Cookies.get('initiatieven.crowdfunding.gent_token') || false

    if(!this.token) return this.showLogin()

    this.getUser()
      .then(user => this.setUser(user))
      .catch(() => this.showLogin())
  }

  getUser() {
    return new Promise((resolve, reject) => {
      const $http = new XMLHttpRequest()
      const url = 'https://api.koalect.com/api/v1/check'

      if(sessionStorage.user) resolve(JSON.parse(sessionStorage.user))

      $http.open('GET', url, true);
      $http.setRequestHeader('Authorization', 'Bearer ' + this.token);
      $http.send();

      $http.onreadystatechange = () => {
        if ($http.readyState === 4 && $http.status === 200) {
          const response = JSON.parse($http.responseText)

          sessionStorage.user = JSON.stringify(response.data.user)

          resolve(response.data.user)
        } else if($http.readyState === 4 && $http.status !== 200) {
          reject()
        }
      }
    })
  }

  setUser(user) {

    var img = user.image_src ? user.image_src : "https://storage.googleapis.com/koalect/images/clients/stadgent/website/no-avatar.png";

    this.profile.querySelector('.js-authentification-avatar').setAttribute('src', img)
    this.profile.querySelector('.js-authentification-name').innerHTML = user.firstname

    this.profile2.querySelector('.js-authentification-avatar').setAttribute('src', user.image_src)
    this.profile2.querySelector('.js-authentification-name').innerHTML = user.firstname

    this.showProfile()
  }

  showProfile() {
    if(this.profile)
      this.profile.classList.add('is-show')

    if(this.profile2)
      this.profile2.classList.add('is-show')
  }

  showLogin() {
    if(this.login)
      this.login.classList.add('is-show')

    if(this.login2)
      this.login2.classList.add('is-show')
  }
}