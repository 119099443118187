class Newsletter {
    constructor() {

        this.add_cf7_class();

    }
    add_cf7_class(){

        var errorElement = document.querySelectorAll("div.field-message");

        document.querySelector("input[type='submit']").addEventListener("click", function(){

            var errorElement = document.querySelectorAll("div.field-message");
            errorElement[0].classList.remove("shown");
        });

        
        document.addEventListener( 'wpcf7invalid', function( event ) {
            errorElement[0].classList.add("error");
            errorElement[0].classList.add("shown");
        }, false );
        document.addEventListener( 'wpcf7mailsent', function( event ) {
            errorElement[0].classList.add("shown");
            errorElement[0].classList.remove("error");
            errorElement[0].classList.add("complete");
        }, false );
    }
}