let gMapsLoaded = false;

console.log('%c%s', 'color: white; background: #00c694;font-size:12px', ' Made with ♥ in Belgium by Koalect 🐨 ')

class App {
  constructor() {
    this.header = new Header();
    this.footer = new Footer();
    new CookiesBar();
    new Authentification();
    // new Anchor();

    this.initSection();
    this.events();
    this.resize();
  }

  initSection() {
    const body = document.querySelector( 'body' )
          classList = body.classList

    if( classList.contains( 'home' )) {
      return new Home()
    }
    if( classList.contains( 'contact' )) {
      return new Contact()
    }

    if(document.querySelector("section.newsletter")){
      return new Newsletter()
    }

    if(document.querySelector("aside.sidebar")){
      return new Filter()
    }

    if(document.querySelector("div#veelgestelde-vragen")){
      return new FaqFilter()
    }

  } 

  events() {
    window.addEventListener( Event.RESIZE, this.resize.bind( this ) );
  }

  resize() {
    W.sw = screen.width;
    W.sh = screen.height;
    W.ww = W.window.innerWidth;
    W.wh = W.window.innerHeight;

    if (this.header && this.header.resize) this.header.resize();

    if (this.footer && this.footer.resize) this.footer.resize();
  }
}

window.gMapsCallback = function() {
  gMapsLoaded = true;

  window.dispatchEvent(
    new window.CustomEvent( 'gMapsLoaded' )
  )
}

let onloadCallbackRecaptcha = function() {};

document.addEventListener( 'DOMContentLoaded', function () {
  let app = new App();
});

