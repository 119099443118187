let Event = (function($) {
    function Event() {}

    Event.MOUSEDOWN = Device.TYPE !== "desktop"
        ? 'touchstart'
        : 'mousedown';

    Event.MOUSEUP = Device.TYPE !== "desktop"
        ? 'touchend'
        : 'mouseup';

    Event.MOUSEMOVE = Device.TYPE !== "desktop"
        ? 'touchmove'
        : 'mousemove';

    Event.CLICK = Device.TYPE !== "desktop"
        ? 'touchstart'
        : 'click';

    Event.ENTER = Device.TYPE !== "desktop"
        ? 'touchstart'
        : 'mouseenter';

    Event.LEAVE = Device.TYPE !== "desktop"
        ? 'touchend'
        : 'mouseleave';

    Event.KEYDOWN = 'keydown';

    Event.WHEEL = 'mousewheel';

    Event.LOADED = 'loaded';

    Event.STEPS = 'steps';

    Event.SUBMIT = 'submit';

    Event.PROGRESS = 'progress';

    Event.SCROLL = 'scroll';

    Event.RESIZE = 'resize';

    Event.BLUR = 'blur';

    Event.FOCUS = 'focus';

    Event.CHANGE = 'change';

    return Event;

})(jQuery);
